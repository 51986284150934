<template>
  <div class="vb-checkout-view">
    <b-container>

      <section class="section section--loader" v-if="loading">
        <loader size="large" :show_timing_slot="true" />
      </section>

      <section class="section" v-if="!loading">
        <b-row>
          <b-col md="12" xl="9">
            <checkout-content-block
              :selected_room="selected_room"
              :passengers="passengers"
              :quote="quote"
            />
          </b-col>
          <b-col md="12" xl="3">
            <sidebar
              :validate_on_mount="validate_on_mount"
              :integration="integration"
              :availability="get_availability()"
              :live_price="quote.live_price"
              :loading="loading"
              :purchasing="purchasing"
              :completed="completed"
              :discount_codes="discount_codes"
              @purchase="sidebar_data => { purchase(sidebar_data) }"
              @commit="event => { this.$store.commit(event.name, event.payload) }">

              <!--slot riepilogo ordine-->
              <template #resume>
                <!--placeholder per allinearlo al content-block-->
                <h2 class="vb-checkout__title">&nbsp;</h2>
              </template>

              <!--slot messaggi di stato-->
              <template #message>
                <!--acquisto completato-->
                <div v-if="completed" class="vb-sidebar__block">
                  <div v-if="success" v-html="'Acquisto completato con successo'" />
                  <div v-else v-html="'C\'è stato un problema.'" />
                </div>
                <!--acquisto in corso-->
                <div v-if="!completed && purchasing" class="text-center">
                  <loader>
                    <template>
                      <span>Prenotazione in corso... non abbandonare o ricaricare la pagina!</span>
                    </template>
                  </loader>
                </div>
              </template>
            </sidebar>
          </b-col>
        </b-row>
      </section>

    </b-container>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { service_handler } from '@/services'
import CheckoutContentBlock from '@alpitour/components/views/content-blocks/checkout.vue'
import {custom_event, get_config} from "@src/utilities";

export default {
  name: 'checkout-view',

  props: {
  },

  components: {
    'checkout-content-block': CheckoutContentBlock,
  },

  computed: {
    ...mapState(['discount_codes']),
    ...mapState('liveat',['selected_room', 'passengers', 'quote']),
  },

  data() {
    return {
      integration: this.$config.integration,
      loading: false,                         // caricamento dati
      purchasing: false,                      // sidebar completatea -> acquisto in corso
      completed: false,                       // acquisto completato
      success: false,                         // esito della purchase() -> cambia il messaggio mostrato all'utente
      validate_on_mount: true,
    }
  },

  created() {
  },

  mounted() {
    this.loading = false
  },

  methods: {
    purchase(sidebar_data) {
      let paxs = []
      this.passengers.passengers.forEach((pax => {
        let tp = {
          id: pax.id,
          age: pax.age,
          passengerType: pax.passengerType,
          details: { fields:  []}
        }
        pax.details.fields.forEach(field => {
          tp.details.fields.push({
            name: field.name,
            value: field.value,
          })
        })
        paxs.push(tp)
      }))

      let book = {
        ...sidebar_data.guest.payload,
        bookingData: {
          payments: sidebar_data.payments,
          id: this.quote.id,
          compositions: {passenger: paxs},
          live_price: this.quote.live_price,
          bookToken: this.quote.bookToken,
        }
      }

      if (sidebar_data.guest.payload.beneficiaryId) book.beneficiaryId = sidebar_data.guest.payload.beneficiaryId

      if (sidebar_data.bill_to_pax !== false) {
        book.guestAddress = sidebar_data.bill_to_pax.payload.guestAddress
        book.guestCity = sidebar_data.bill_to_pax.payload.guestCity
        book.guestPostalCode = sidebar_data.bill_to_pax.payload.guestPostalCode
        book.guestProvince = sidebar_data.bill_to_pax.payload.guestProvince
        book.guestFiscalCode = sidebar_data.bill_to_pax.payload.guestFiscalCode        
      }
            
      this.purchasing = true
      this.success = false

      window.scrollTo(0,0)
      service_handler(
        'book', book
      )
        .then(
          (success) => {
            this.success = true
            this.purchasing = false
            this.completed = true
            window.scrollTo(0,0) // la sidebar sparisce quasi per intero => riporto lo scroll della pagina in alto

            custom_event('confirmedCheckout', get_config('integration.company'), success.data.live_price, 'partnerBehaviour')

            if(success.data.boughtProductTypes){
              success.data.boughtProductTypes.forEach(productType => {
                custom_event('book', productType, success.data.live_price, 'productType')
              })
            }

            // se non ripassa per la config non aggiorna il credito nel pannello utente
            /*this.$router.push({
              name: 'confirm',
              params: {
                holiday_voucher_id: success.data.holidayVoucherId.toString(),
                confirm_code: success.data.confirmcode,
              }
            })*/

            window.location.href = '/confirm/' + success.data.holidayVoucherId.toString() + '/' + success.data.confirmcode

          },
          (error) => {
            this.log('[book] - error')
            this.log(error.response)
            this.validate_on_mount = true
            this.success = false
            this.purchasing = false
            custom_event('failedCheckout', get_config('integration.company'), book.bookingData.live_price, 'partnerBehaviour')
          }
        )
    }
  }

}
</script>
<style lang="scss" scoped>
</style>