<template>

  <div class="vb-checkout">
    <router-link :to="{name: 'quote'}">
      <span class="vb-detail__back">indietro</span>
    </router-link>
    <h1 class="vb-heading h2 mt-3">I dettagli della tua prenotazione</h1>
    <p>Verifica con attenzione i dettagli del pacchetto che stai prenotando.</p>
    <pratica
        :services="quote.services"
        :passengers="passengers.passengers"
    />
  </div>
</template>

<script>
import Pratica from '@alpitour/components/blocks/pratica.vue'

export default {
  name: 'checkout-block',

  props: {
    selected_room: {},
    quote: {},
    passengers: [],
  },

  components: {
    'pratica': Pratica
  },

  data() {
    return {
    }
  },

  mounted() {

  },

  computed: {
  },

  methods: {

  }
}
</script>